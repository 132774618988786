import Domain from "../domain";
export default {
    methods: {
        getCouponAll: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCouponAllById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/details/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCouponByFilter: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/details?availble_type=" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getUnusedCoupons: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/details/non/used",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getUsedCoupons: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/details/used",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getExpiredCoupons: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/coupon/expired/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postCouponUsed: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/coupon/used",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCouponProducts: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/coupon/details/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        }
    }
}