import Domain from "../domain";
export default {
    methods: {
        getProductList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/details/",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderLimitApi: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/company/setting/order",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getStoreProductList: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN +
                    "portal/product/details/" + params
                    // Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getStoreProductCount: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/count/details/" + params
                    // Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductCount: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/count/details/?category=" + id
                    // Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postCartProducts: async function(cartProductArray) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/set/details",
                    cartProductArray
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postFollowStore: async function(followObj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/profile/follow/store",
                    followObj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        saveWishliat: async function(wishlistItem) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/wishlist/product",
                    wishlistItem,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getWishList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/wishlist/product/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getTrendingCategoriesApi: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/categories/tranding",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductInfo: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/overview/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        saveReportReson: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/report",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postAttChange: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/by/attribute",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postRatingReviewStatus: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/review/status/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        postRatingReview: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/review",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        postProductInquiry: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/questions/",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
            } catch (error) {
                throw error.response.data;
            }
        },
        fetchDeliveryRatesByPIds: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/delivery/partners",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        fetchAgentDeliveryRatesByPIds: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "service/guest/product/delivery/partners/sathosa",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        getReviewRatingsByPid: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/total/rating/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        // Domain.APP_DOMAIN + "portal/product/availble/change/city/sathosa",
        postAvailableByStore: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/availble/change/city/pola",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductInquiry: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/questions/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductCommonInquiries: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/product/questions/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postCheckoutOrder: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/order",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        postAgentCheckoutOrder: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "service/guest/order/sathosa",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        putNewTempCheckoutAddress: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/addresses/change/new",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        putNewCheckoutAddress: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/addresses/change",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        putTempNewCheckoutAddress: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/profile/addresses/change/new",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        putReviewImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/product/upload/image",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        postCheckAvailableStock: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/available/details",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        getHomeTabProducts: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/collections/front/product/list",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                return error;
            }
        },
        getProductListByCategoryID: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/details?category=" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductListFiltersByCategoryID: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/categories/assing/all/filter/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductListByCIDFilters: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/details/?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductCountByCIDFilters: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/count/details/?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProductListByHPCategories: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/homepage/new/mobile/details?page=1&perpage=10",
                    // Domain.APP_DOMAIN + "portal/product/homepage/details" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getRatingIndividual: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/product/review/count/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCloseTime: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/close/time/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getAvailableForZone: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/delivery/area/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        getVoucherCheck: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/voucher/check/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        // function genarate session ID for Internal VUE App Scripts
        // getMastercardSessionID: async function(obj) {
        //   try {
        //     let response = await this.axios.post(Domain.APP_DOMAIN + "portal/ipg/mastercard/create/checkout/session", obj , Domain.APP_BUYER_HEADER);
        //     return response.data;
        //   } catch (error) {
        //     throw error.response;
        //   }
        // },
        // function genarate session ID for external ipg.html - deepal
        getMastercardSessionIDPublic: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/ipg/boc/create/checkout/session/public",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        // update order status after pay by card through payment gateway - deepal
        saveCardPaymentSuccess: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/order/ipg/payment/confirm",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        moveHnbIpg: async function(id, obj) {
            try {
                let response = await this.axios.get(
                    "https://ipg.cochchi.lk/payment_form.php?order_id=" + id,
                    obj
                );
                return response;
            } catch (error) {
                throw error.response;
            }
        },
        getHomeTabsNewArrive: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/new/arrivals/details/",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        getHomeTabsOffers: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/offers/details/",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        getHomeTabsPopular: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/top/selling",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
    },
};